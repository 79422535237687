<template>
  <div class="connect">
    <circle1 v-if="loading"></circle1>
    <h5> {{ $t('common.loading') }}</h5>
    <i class="el-icon-error" v-if="errMsg"></i>
    <p v-if="errMsg">{{ errMsg }}</p>
  </div>
</template>
<script>
import { Circle as Circle1 } from 'vue-loading-spinner'
import { Login } from '@/api/login'
import { setToken, removeToken } from '@/common/auth'
import { setUserRole, setLastLoginInfo, getLastLoginInfo, setUserTreeSetting, getUserTreeSetting, setDefaultMap, setGoogleKey, getVisitedViewInfo } from '@/common/cache'
import { BASE_URL } from '@/api/config'
import { parseQueryString } from '@/common/utils'
import { mapMutations } from 'vuex'
export default {
  name: 'auth',
  components: {
    Circle1
  },
  beforeCreate() {
    document.querySelector(':root').style.minWidth = '400px'
  },
  created() {
    this.errMsg = ''
    this.loading = false
  },
  mounted() {
    this.go()
  },
  data() {
    return {
      loading: true,
      errMsg: '',
      form: {
        username: "wangyuxiang",
        password: "vdfwyx"
      }
    }
  },
  methods: {
    ...mapMutations({
      setUserName: 'SET_USER_NAME',
      setUserId: 'SET_USER_ID',
      setLoginInfo: 'SET_LOGIN_INFO',
      setCurrentHoldId: 'SET_CURRENT_HOLD_ID',
      setCurrentHoldName: 'SET_CURRENT_HOLD_NAME',
      setAllViews: 'SET_ALL_VIEWS',
      setCurrentMap: 'SET_CURRENT_MAP',
      setMonitorLeft: 'SET_MONITOR_LEFT'
    }),
    go() {
      var searchString = ''
      var startIndex = window.location.href.indexOf('?')
      if (startIndex != -1) {
        var endIndex = window.location.href.indexOf('#')
        if (endIndex < startIndex) {
          searchString = window.location.href.substring(startIndex)
        } else {
          searchString = window.location.href.substring(startIndex, endIndex)
        }
      }
      var qs = parseQueryString(searchString)
      this.loading = true
      let form = this.form
      Login(form.username, form.password).then(ret => {
        if (!ret.data.errCode || ret.data.errCode === 10) {
          if (ret.data.errCode === 10) {
            // 该账号有效期还有{0}天
            this.$alert(ret.data.errMsg);
          } else if (ret.data.errCode === 11) {
            // 用户已过期
            this.$alert(ret.data.errMsg)
            return
          }
          let state = getUserTreeSetting({})
          state = Object.assign(state, {
            checkList: [],
            containChild: true
          })
          // 清空勾选列表
          setUserTreeSetting(state)
          // 显示账号有效期
          setToken(ret.data.loginKey)
          if (ret.data.info.GoogleKey) {
            setGoogleKey(ret.data.info.GoogleKey)
            if (this.config.default_map === 'Google') {
              setDefaultMap('Google2')
              this.setCurrentMap('Google2')
            }
          }
          // 增加图片地址
          ret.data.info.PlatformLogo = `${BASE_URL}/assets/Logo?holdid=${ret.data.info.HoldID}`
          this.setUserName(ret.data.info.LoginName) // 登录用户名
          this.setUserId(ret.data.info.UserID) // 登录用户ID
          this.setLoginInfo(ret.data.info) // 保存登录全部信息
          this.setCurrentHoldName(ret.data.info.HoldName) // 保存当前选择用户名
          this.setCurrentHoldId(ret.data.info.HoldID) // 保存当前选择用户ID
          // 保存最后登录信息(标题，图标)
          const lastLoginInfo = getLastLoginInfo()
          lastLoginInfo.PlatformTitle = ret.data.info.PlatformTitle
          lastLoginInfo.HoldID = ret.data.info.HoldID
          lastLoginInfo.PlatformLogo = ret.data.info.PlatformLogo
          setLastLoginInfo(lastLoginInfo)
          setUserRole({ UserType: ret.data.info.UserType, UserFunList: ret.data.info.allFuns })
          // 加载缓存的标签页
          const views = getVisitedViewInfo()
          if (views && views.userId === ret.data.info.UserID && views.v === window.compileVersion) {
            this.setAllViews(views)
          } else {
            this.setAllViews({ visitedViews: [], cachedViews: [] })
          }
          if (ret.data.info.UserType === 961 || ret.data.info.UserType === 962 || ret.data.info.UserType === 963) {
            this.setMonitorLeft(false)
            // 安装人员
            this.$router.push('/wono/manage')
          } else {
            this.$router.push('/online/monitor')
          }
        } else {
          removeToken()
          setUserRole()
          this.errMsg = ret.data.errMsg
        }
      }).catch(err => {
        if (!err.response) {
          this.errMsg = this.$t('common.netError')
        } else if (err.response.status === 500) {
          this.errMsg = this.$t('common.serverError')
        } else {
          this.errMsg = err.message
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.connect {
  height: calc(100vh - 17px);
  width: calc(100vw - 17px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h5 {
    text-align: center;
    line-height: 36px;
    font-size: 16px;
  }

  i {
    margin-bottom: 12px;
    opacity: .56;
    font-size: 2em;
  }
}
</style>